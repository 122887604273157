<template>
  <main
    class="
      w-screen
      h-screen
      bg-legend-black
      dark:bg-gray-50 dark:bg-opacity-20
      bg-opacity-30
      fixed
      top-0
      left-0
      z-40
    "
  >
    <div
      class="
        xl:w-1/3
        lg:w-1/2
        md:w-3/5
        w-97-percent-width
        h-screen
        bg-primary-background
        dark:bg-dark-mode-main
        shadow-lg
        ml-auto
        py-8
        relative
        z-50
      "
    >
      <slot name="cart" />
    </div>
  </main>
</template>

<script>
export default {
  name: 'CustomModal',
}
</script>
